.monthSelection {
    display: flex;
    justify-content: center;
    align-items: center;
    .dropDownMonth {
        flex-grow: 2;
    }
    .dropDownYear {
        flex-grow: 1;
    }
}