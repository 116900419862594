$footer-height: 2rem;
$primaryColor: #108615;

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 $footer-height;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  img {
    width: 32px;
    height: 32px;
  }

  .homeLink {
    display: flex;
    text-decoration: none;
    align-items: center;
  }
}

a:active {
  color: inherit;
}

footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  box-sizing: border-box;
  background-color: $primaryColor;
  position: absolute;
  left: 0;
  bottom: 0;
  height: $footer-height;
  width: 100%;
  overflow: hidden;

  :global(.ms-Button-label) {
    color: white;
  }
}

.App {
  text-align: center;
  padding: 0px 14px;
  display: flex;
  justify-content: center;
}

.data {
  max-width: 900px;
  flex-grow: 1;
}

.unauthorized {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;

  button {
    height: 80px;
    width: 160px;
  }
}

.textPage {
  text-align: left;
}

.banner {
  max-width: 100%;
  height: auto;
}

.loadingIndicatorContainer {
  color: $primaryColor;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}