.tile {
  display: flex;
  border-top: 1px solid #d3d3d3;
  margin-top: 4px;
  padding-top: 4px;
  .time {
    width: 70px;
  }
  .data {
    padding-left: 4px;
    flex-grow: 2;
    display: grid;
  }
  .title {
    font-weight: bolder;
    font-size: 1.3em;
  }
  .state {
    width: 8px;
    &.stateApproved {
      background-color: #639754;
    }
    &.statePending {
      background-color: #ffd301;
    }
    &.stateDeclined {
      background-color: #e03c32;
    }
  }
}
