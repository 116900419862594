.dayTile {
    text-align: left;
    border-bottom: 1px solid #d3d3d3;
    padding-top: 8px;
    padding-bottom: 4px;
    .date {
        font-weight: 600;
    }
    .noEvents {
        color: rgba(0, 0, 0, 0.6);
    }
    display: block;
}
